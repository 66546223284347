HomeController.$inject = ['FroalaEditor', 'logService', '$scope', 'allevaApi', '$timeout', 'FileUploader', 'noty', 'moment', '$sce', '$localStorage', '$state', 'gClassroom', '$filter'];
export default function HomeController(FroalaEditor, logService, $scope, allevaApi, $timeout, FileUploader, noty, moment, $sce, $localStorage, $state, gClassroom, $filter) {
  /***************************
   * Params
   **************************/
  $scope.homeController = $scope;
  $scope.checklistBadgeCount = 0;

  // >>>>>>> DEMO <<
  $scope.isDemoAccount = false;

  // >>>>>>> Page <<<<<<<
  $scope.setPageTitle("Alleva Family - Dashboard");

  $scope.fetchingEmails = false;

  // >>>>>>> Nav <<<<<<<
  $scope.isOverview      = true;
  $scope.isInbox         = false;
  $scope.isMyTasks       = false;
  $scope.isLoadingClient = false;

  $scope.isInboxMailView       = true;
  $scope.isSentMailView        = false;
  $scope.isTrashMailView       = false;
  $scope.isAttachementMailView = false;

  $scope._menuItemOverview = 1;
  $scope._menuItemInbox    = 2;
  $scope._menuItemMyTasks  = 3;

  $scope._emailInboxView      = 11;
  $scope._emailSentView       = 12;
  $scope._emailTrashView      = 13;
  $scope._emailAttachmentView = 14;

  // >>>>>>> Data <<<<<<<
  // Froala
  $scope.froalaEdit = null;

  // Sessions
  $scope.upcomingSessions = null;

  // Courses
  $scope.courseData = { isGuardian: $scope.isClientRole($scope.currentUser.roleObj), courses : []};

  // Mail
  $scope.currentEmail = null;
  $scope.composeEmail = {show:false};
  $scope.emails       = {};

  // My Tasks
  $scope.completedForms = [];
  $scope.incompleteForms = [];
  $scope.paginateIncompleteList = {};
  $scope.paginateCompleteList = {};
  $scope.checklistLoaded = false;

  // >>>>>>> Constants <<<<<<<
  const _fileUploadLimit = 5;
  const _FamilyFormTypeApplication = 2000;
  const _FamilyFormTypeAcceptance  = 2001;
  const _FamilyFormTypeDischarge   = 2002;
  const _FamilyFormTypeTreatmentPlan = 2003;
  const _FamilyFormTypeTreatmentPlanReview = 2004;
  const _FamilyMemberType = 2500;
  const _ConsentFormType = 1;
  const _AdmissionFormType = 2;
  const _DischargeFormType = 3;
  const _BamrFormType = 4;
  const _RecTherapyFormType = 5;
  const _CaseManagementFormType = 6;
  const _AssessmentFormType = 7;
  const _TreatmentPlanReviewFormType = 8;

  $scope._StatusInProgress          = "In Progress";
  $scope._StatusPending             = "Pending";
  $scope._ButtonContinue            = "Continue";
  $scope._ButtonStart               = "Start";

  // >>>>>>> Misc <<<<<<<
  $scope.numberFileUploads = 0;

  /***************************
   * Init
   **************************/
  $scope.initHome = () => {
    logService.console.log("Dashboard");

    // Demo account?
    if($scope._Subdomain.toLowerCase() === 'developallevasoftrehab' ||
      $scope._Subdomain.toLowerCase() === 'allevademo') {
      $scope.isDemoAccount = true;
    }

    /**************** */
    /** Only enable if you want users to not be able to log in during downtime.
    /**************** */
    // if(!$scope.isDemoAccount){
    //   new noty({
    //     text   : "We are currently undergoing unscheduled maintenance. Please check back soon.",
    //     timeout: false,
    //     type   : 'warning',
    //   }).show();

    //   $scope.logout();
    // }
    /**************** */


    // Navigation
    $scope.activeNav($scope._PageDashboard);

    // Get Current Client
    $scope.setCurrentClient();

    if($scope.currentClient && $scope.currentClient.isClient){
      $scope.currentClient.emails = $scope.emails;
      logService.console.info("currentClient", $scope.currentClient);
      $scope.refreshInbox();
      $scope.getUpcomingSessions();
      getChecklistBadgeCount().then(count => {
        $scope.checklistBadgeCount = count;
      }).catch(error => {
        new noty({
          text   : error,
          timeout: 8000
        }).show();
      });
    }
    else{
      let alert = "<h5>Dashboard</h5><p>Sorry, you can't view the dashboard because you don't have any active clients.</p>";
      new noty({
        text   : alert,
        timeout: 8000
      }).show();
      $state.go('myApplications', {});
    }

  };


  /***************************
   * Actions
   **************************/
  $scope.clientSelected = (client) => {
    $scope.checklistBadgeCount = 0;
    logService.console.info("Client Selected: ", client);
    $scope.isLoadingClient = true;
    $scope.setCurrentClient(client);
    $scope.refreshInbox();
    getChecklistBadgeCount().then(count => {
      $scope.checklistBadgeCount = count;
    }).catch(error => {
      new noty({
        text   : error,
        timeout: 8000
      }).show();
    });
    $scope.getUpcomingSessions();
    $scope.menuClick($scope._menuItemOverview);

    $timeout(() => {
      $scope.isLoadingClient = false;
    }, 1800);
  };

  $scope.getUpcomingSessions = () => {
    $scope.upcomingSessions = null;

    var params = {};
    if($scope.isClientRole($scope.currentUser.roleObj)){
      params = {
        "leadId"  : $scope.currentClient.leadId,
        "clientId": $scope.currentClient.clientId
      };
    }
    else{
      params = {
        "leadId"     : $scope.currentClient.leadId,
        "clientId"   : $scope.currentClient.clientId,
        "isNonClient": true,
        "rehabUserId": $scope.currentUser.rehabUserId
      };
    }

    $scope.getSessions(params)
      .then(result => {

        var sessionList = [];

        // Get Rehab info - Make sure we have the most up to date rehab info (like tomezone change)
        $scope.getRehabDetails()
        .then(rehabDetails => {
          $localStorage.rehabDetails = rehabDetails;
          $scope.rehabDetails        = rehabDetails;

          rehabDetails.facilities.forEach(facility => {
            if(facility.rehabFacilityId == $scope.currentUser.facilityId){
              $scope.currentFacility = facility;
            }
          });
          result.forEach(session => {
          var timeZone          = moment.tz.guess();
            //convert appoitment date into UTC using facility tz
          var utcAppointmentDate=  moment.tz(session.startDate + ' '+ session.startTime, session.timeZoneName).utc();
          //Now convert utc date into client date
          var clientAppointmetDate =moment.utc(utcAppointmentDate).tz(timeZone);

            session.dateTime=   clientAppointmetDate.format("MMM DD @ h:mm a");
            session.launchUrl = $scope.launchUrl;
            session.isToday  = moment(clientAppointmetDate).isSame(moment(), 'day');
            session.timeZone = moment.tz.zone(timeZone).abbr(new Date().getTimezoneOffset());
            sessionList.push(session);

        });

        logService.console.info("Upcoming Sessions", sessionList);
        $scope.upcomingSessions = sessionList;

        })
        .catch((error) => {
          logService.console.error(error);
          let alert = "<p>Could not get Rehab Information to display sessions. Please contact support.</p>";
          new noty({
            text   : alert,
            type   : 'error',
            timeout: false
          }).show();
        });

      }).catch((error) => {
        $scope.upcomingSessions = [];
        logService.console.error(error);
      });
  };

  $scope.launchUrl = (url) => {
    window.open(url);
  };

  $scope.openEmail = (email) => {

    if(!email.isRead && email.messageToRehabUserId === $scope.currentUser.rehabUserId){ // update DB with isRead flag for unread emails only
      $scope.readEmail(email.familyMessageId);
    }

    email.isRead        = true;
    $scope.currentEmail = email;

    logService.console.info("email opened", email);

    // Download message body
    if(!email.message){
      $scope.getEmailMessage(email.familyMessageId)
      .then((email) => {
        logService.console.log("Got Email Body");
        $scope.isLoading = false;

        $scope.currentEmail.message = email.message;

        $scope.filterEmails();

      })
      .catch((error) => {
        logService.console.error(error);
        $scope.isLoading = false;

        if (typeof(error) === 'string' || error instanceof String) {
          new noty({
            text: error
          }).show();
        }
      });
    }
  };

  $scope.compose = (show) => {
    $scope.composeEmail = {
      to  : $scope.rehabDetails.rehabName,
      show: show
    };
    $scope.froalaEdit.html.set("");
    $scope.uploader.clearQueue();
  };

  $scope.validateSendEmail = (form) => {

    // Update message
    $scope.composeEmail.message        = $scope.froalaEdit.html.get();
    $scope.composeEmail.messagePreview = textEllipsis($scope.froalaEdit.$el.text(), 100);

    if(!$scope.composeEmail.subject && !$scope.composeEmail.message && $scope.uploader.queue.length === 0){
      let alert = "<p>The email has no content. Please add a message or upload a file.</p>";
      new noty({
        text: alert,
        type: 'warning'
      }).show();

      return false;
    }

    // Send Email
    $scope.isLoading = true;

    $scope.sendEmail()
    .then((result) => {

      if($scope.uploader.queue.length === 0) { // no attachments - sent mail
        logService.console.log("Email Sent!");
        $scope.isLoading = false;

        $scope.closeEmail(true);
        $scope.refreshInbox();

        let success = "<p>Email sent!</p>";
        new noty({
          text: success,
          type: 'success',
        }).show();
      }
      else{
        result.show         = true;
        $scope.composeEmail = result;
        $scope.uploader.uploadAll();
      }


    })
    .catch((error) => {
      logService.console.error(error);
      $scope.isLoading = false;

      if (typeof(error) === 'string' || error instanceof String) {
        new noty({
          text: error
        }).show();
      }
    });
  };


  $scope.closeEmail = (sent = false) => {
    if(($scope.froalaEdit.html.get() || $scope.uploader.queue.length > 0) && !sent){
      BootstrapDialog.show({
        title  : 'Discard Message?',
        message: 'If you leave this page you will lose any unsent messages.',
        type   : BootstrapDialog.TYPE_WARNING,
        buttons: [{
            label : 'Keep Editing',
            action: function(dialog) {
              dialog.close();
            }
        }, {
          label   : 'Discard',
          cssClass: 'btn-primary',
          action  : function(dialog){
            $scope.$apply(() => {
              $scope.currentEmail = null;
              $scope.compose(false);
            });

            dialog.close();
          }
        }]
    });
    }
    else{
      $scope.currentEmail = null;
      $scope.compose(false);
    }
  };

  $scope.menuClick = (item) => {

    $scope.isOverview = false;
    $scope.isInbox    = false;
    $scope.isMyTasks  = false;

    switch (item) {
      case $scope._menuItemOverview:
        $scope.isOverview = true;
        break;
      case $scope._menuItemInbox:
        $scope.isInbox = true;
        break;
      case $scope._menuItemMyTasks:
        $scope.isMyTasks = true;
        refreshChecklist();
        break;
      default:
        break;
    }
  };

  $scope.getClientCourses = () => {
    gClassroom.isAuthenticated()
        .then(isAuth => {
            $scope.googleClassroomsAuthenticated = isAuth;

            if(isAuth){
              gClassroom.getClientCourses({"userId" : $scope.currentClient.emailAddress})
              .then(courses => {

                if(courses.courses){
                  courses.courses.forEach(course => {
                    course.launchUrl          = $scope.launchUrl;
                    course.sendGuardianInvite = $scope.sendGuardianInvite;
                  });
                }
                logService.console.info('ClientCourses', courses);
                $scope.courseData.courses = courses.courses ? courses.courses : [];
              })
              .catch(error =>{
                logService.console.info('ClientCourses - ERROR', error);
              });
            }

        }
    );
  };

  $scope.sendGuardianInvite = (course) => {
    BootstrapDialog.show({
      title   : 'Google Classroom',
      message : 'You are about to send a guardian invitation for the following course: <b class="noty-bold">' + course.name + '</b> \n\nYou will recieve an invitation link from <b class="noty-bold">Google Classrooms</b>. Please follow the instructions provided on the email. \n\nSend course invite to <b class="underline">' + $scope.currentUser.email + '</b>?',
      type    : BootstrapDialog.TYPE_WARNING,
      closable: false,
      buttons : [{
          label : 'Cancel',
          action: function(dialog) {
            dialog.close();
          }
      }, {
        label   : 'Invite Me',
        cssClass: 'btn-primary',
        action  : function(dialog){
          dialog.enableButtons(false);

          let invite = {
            invitedEmailAddress: $scope.currentUser.email,
            studentId          : $scope.currentClient.emailAddress
          };
          gClassroom.inviteGuardian(invite)
              .then(() => {

                dialog.enableButtons(true);
                let alert = "Your invitation has been sent. Please check your email.";
                new noty({
                  text   : alert,
                  type   : 'success',
                  timeout: 10000
                }).show();
                dialog.close();
              })
              .catch(error =>{
                dialog.enableButtons(true);
                logService.console.info('InviteGuardian - ERROR', error);
              });

        }
      }]
    });
  };

  $scope.startFormClicked = (client, form, isApplication = false) => {
    $scope.currentForm                    = form;
    $scope.currentForm.saveForLater       = false;
    $scope.currentForm.saveFromAutosave   = false;
    $scope.currentForm.formSubmitted      = $scope.formDidFinishSubmitting;
    $scope.currentApplicant               = client;
    $scope.currentApplicant.isApplication = isApplication;

    if(form.familyFormType == _FamilyFormTypeTreatmentPlan){
      $scope.showTreatmentPlan   = true;
      $scope.showCustomForm      = false;
    }
    else if(form.familyFormType == _FamilyFormTypeTreatmentPlanReview){
      $scope.showTreatmentPlanReview = true;
    }
    else {
      $scope.showCustomForm      = true;
      $scope.showTreatmentPlan   = false;
    }
    $scope.showCustomFormView  = false;
};

  $scope.viewFormClicked = (client, form) => {
  $scope.currentForm      = form;
  $scope.currentApplicant = client;

  if(form.familyFormType == _FamilyFormTypeTreatmentPlan){
    $scope.showTreatmentPlan = true;
  }
  else if(form.familyFormType == _FamilyFormTypeTreatmentPlanReview){
    $scope.showTreatmentPlanReview = true;
  }
  else {
    $scope.showCustomForm      = false;
    $scope.showCustomFormView  = true;
  }
};

    /***************************
     * Custom Form Callbacks
     **************************/
    $scope.formDidFinishSubmitting = (form = null) => {
      // From Autosave
      if($scope.currentForm.saveFromAutosave) {
          $scope.currentForm.saveFromAutosave = false;

          let alert = "<h5>Autosave complete</h5><p>You form has been successfully autosaved.</p>";
          new noty({
          text: alert,
          type: 'success'
          }).show();
          return;
      }

      $scope.showCustomForm = false;

      $scope.isLoading = true;

      // Get next form if any

      $scope.getCurrentUserDetails((success) => {
          $scope.isLoading = false;
      });

      $scope.getUpcomingSessions();

      refreshChecklist();
  };

  /***************************
   * CRUD
   **************************/
  // GET
  $scope.getInbox = () => {
    let params = {
      "rehabUserId": $scope.currentUser.rehabUserId,
      "leadId"     : $scope.currentClient.leadId
    };

    return allevaApi.Messages.getPortalEmails(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.getEmailMessage = (emailId) => {
    let params = {
      "messageId": emailId
    };

    return allevaApi.Messages.getEmailMessage(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  $scope.getSessions = (params) => {

    return allevaApi.FamilyAPI.getClientUpcomingSessions(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

  const getChecklistBadgeCount = () => {
    let filter =
    {
      FacilityId : $scope.currentClient.facilityId,
      RehabUserId: $scope.currentUser.rehabUserId,
      LeadId: $scope.currentClient.leadId
    };

    return allevaApi.FamilyAPI.getParticipantChecklistBadgeCount(filter)
    .then(result => {
        return Promise.resolve(result);
    })
    .catch(error => {
        return Promise.reject(error);
    });
  }

  const getParticipantChecklist = filter => {
    return allevaApi.FamilyAPI.getParticipantChecklist(filter)
        .then(result => {
            return Promise.resolve(result);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

  // POST
  $scope.readEmail = (emailId) => {
    let params = {
      "messageId": emailId
    };

    return allevaApi.Messages.readEmail(params)
      .then((response) => {}).catch((error) => {});
  };

  $scope.sendEmail = () => {

    var isDraft        = $scope.uploader.queue.length === 0 ? false : true;
    var hasAttachments = $scope.uploader.queue.length === 0 ? false : true;

    let params = {
      "FamilyMessageId"       : $scope.composeEmail.familyMessageId,
      "LeadId"                : $scope.currentClient.leadId,
      "ClientId"              : $scope.currentClient.clientId,
      "messageFromRehabUserId": $scope.currentUser.rehabUserId,
      "messageToRehabId"      : $scope.rehabDetails.rehabId,
      "messageToFacilityId"   : $scope.currentUser.facilityId,
      "subject"               : $scope.composeEmail.subject,
      "message"               : $scope.composeEmail.message,
      "messagePreview"        : $scope.composeEmail.messagePreview,
      "isRead"                : false,
      "hasAttachments"        : hasAttachments,
      "isDraft"               : isDraft,
      "rehabUserId"           : $scope.currentUser.rehabUserId,
      "createdBy"             : -2,                                    // Family app default
      "createdDate"           : new Date(),
      "isDeleted"             : false
    };

    return allevaApi.Messages.sendEmail(params)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };

   /***************************
   * Callbacks
   **************************/
  $scope.closeForm = () => {
    $scope.showCustomForm      = false;
    $scope.showCustomFormView  = false;
    $scope.showTreatmentPlan   = false;
    $scope.showTreatmentPlanReview = false;
    $scope.getUpcomingSessions();
    refreshChecklist();
  }

  /***************************
   * Uploader
   **************************/
  $scope.uploader = new FileUploader({
    url       : `${ALLEVA_API_HOST}/family/upload-attachment`,
    queueLimit: _fileUploadLimit
  });

  $scope.uploader.headers.Authorization = 'Bearer ' + $localStorage.access_token;

  /****  Filters  ****/
  // a sync filter
  $scope.uploader.filters.push({
    name: 'syncFilter',
    fn  : function (item /*{File|FileLikeObject}*/ , options) {
      logService.console.log('syncFilter');
      return this.queue.length < 10;
    }
  });

  // an async filter
  $scope.uploader.filters.push({
    name: 'asyncFilter',
    fn  : function (item /*{File|FileLikeObject}*/ , options, deferred) {
      logService.console.log('asyncFilter');
      setTimeout(deferred.resolve, 1e3);
    }
  });

  $scope.uploader.filters.push({
    name: 'sizeFilter',
    fn  : function(item) {
      return item.size < 5242880;
    }
  });

  /****  Actions  ****/
  $scope.removeItem = (item) => {
    item.remove();
    $scope.numberFileUploads--;
  };


  /****  CALLBACKS ****/
  $scope.uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/ , filter, options) {
    logService.console.info('onWhenAddingFileFailed', item, filter, options);

    // let alert = "<p>" + item.name + " could not be added because upload limit was reached.</p>";
    //   new noty({
    //     text   : alert,
    //     type: 'warning',
    //   }).show();

    if(filter.name == "sizeFilter"){
      let alert = "<p><b class='noty-bold'>" + item.name + "</b> is too large (" + bytesToSize(item.size) +  "). Maximum file size limit is 5MB.</p>";
      new noty({
        text   : alert,
        type   : 'error',
        timeout: false
      }).show();
    }

    $scope.numberFileUploads++;

  };

  $scope.uploader.onAfterAddingFile = function (fileItem) {
    logService.console.info('onAfterAddingFile', fileItem);

    $scope.numberFileUploads++;
  };

  $scope.uploader.onAfterAddingAll = function (addedFileItems) {
    logService.console.info('onAfterAddingAll', addedFileItems);

    let success = "<p><b class='noty-bold'>" + addedFileItems.length + "</b> file(s) added.</p>";
    new noty({
      text: success,
      type: 'success',
    }).show();

    if($scope.numberFileUploads > _fileUploadLimit){
      let alert = "<p>Some files could not be added because it exceeded the upload limit.</p>";
      new noty({
        text   : alert,
        type   : 'warning',
        timeout: false
      }).show();

      $scope.numberFileUploads = _fileUploadLimit;
    }

  };
  $scope.uploader.onBeforeUploadItem = function (item) {
    logService.console.info('onBeforeUploadItem', item);

    item.formData.push({ messageIds:  $scope.composeEmail.childFamilyMessageIds, clientId: $scope.composeEmail.clientId, leadId: $scope.composeEmail.leadId});

  };
  $scope.uploader.onProgressItem = function (fileItem, progress) {
    logService.console.info('onProgressItem', fileItem, progress);
  };
  $scope.uploader.onProgressAll = function (progress) {
    logService.console.info('onProgressAll', progress);
  };
  $scope.uploader.onSuccessItem = function (fileItem, response, status, headers) {
    logService.console.info('onSuccessItem', fileItem, response, status, headers);
  };
  $scope.uploader.onErrorItem = function (fileItem, response, status, headers) {
    logService.console.info('onErrorItem', fileItem, response, status, headers);
  };
  $scope.uploader.onCancelItem = function (fileItem, response, status, headers) {
    logService.console.info('onCancelItem', fileItem, response, status, headers);
  };
  $scope.uploader.onCompleteItem = function (fileItem, response, status, headers) {
    logService.console.info('onCompleteItem', fileItem, response, status, headers);
  };
  $scope.uploader.onCompleteAll = function () {
    logService.console.info('onCompleteAll');

    $scope.sendEmail()
    .then((result) => {

      logService.console.log("Email Sent!");
        $scope.isLoading = false;

        $scope.closeEmail(true);
        $scope.refreshInbox();

        let success = "<p>Email sent!</p>";
        new noty({
          text: success,
          type: 'success',
        }).show();
    })
    .catch((error) => {
      logService.console.error(error);
      $scope.isLoading = false;

      if (typeof(error) === 'string' || error instanceof String) {
        new noty({
          text: error
        }).show();
      }
    });

  };

  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }

  /***************************
   * Froala Editor
   **************************/
  $scope.initEditor = () => {
    $scope.froalaEdit = new FroalaEditor('#composeEmail', {
      dragInline        : false,
      colorsHEXInput    : true,
      colorsStep        : 8,
      imagePaste        : false,
      heightMin         : 400,
      heightMax         : 1000,
      quickInsertEnabled: false,
      toolbarButtons    : {
        'moreText': {
          'buttons'       : ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting'],
          'buttonsVisible': 4

        },
        'moreParagraph': {
          'buttons'       : ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'paragraphFormat', 'quote'],
          'buttonsVisible': 3
        },
        'moreRich': {
          'buttons': ['insertHR'],
          // 'buttons': ['insertLink', 'insertImage', 'insertFile', 'insertHR'],
          'buttonsVisible': 2
        },
        'moreMisc': {
          'buttons'       : ['undo', 'redo', 'fullscreen', 'html'],
          'align'         : 'right',
          'buttonsVisible': 2
        }
      }
    });
    $scope.hideBanner();
  };

  $scope.hideBanner = () => {
    $timeout(() => {
      $('a[href^="https://"]').each(function () {
        var a      = $(this);          // Get current url
        var parent = a[0].parentNode;
        parent.removeChild(a[0]);
      });
    }, 500);

  };

  /***************************
   * Navigation
   **************************/
  $scope.changeInbox = (menuItem) => {

    $scope.refreshInboxView();

    switch (menuItem) {
      case $scope._emailInboxView:
        $scope.isInboxMailView = true;
        break;
      case $scope._emailSentView:
        $scope.isSentMailView = true;
        break;
      case $scope._emailTrashView:
        $scope.isTrashMailView = true;
        break;
      case $scope._emailAttachmentView:
        $scope.isAttachementMailView = true;
        break;

      default:
        break;
    }
  };

  $scope.refreshInboxView = () => {
    $scope.isInboxMailView       = false;
    $scope.isSentMailView        = false;
    $scope.isTrashMailView       = false;
    $scope.isAttachementMailView = false;
  };

  /***************************
   * Helpers
   **************************/
  $scope.createDownloadUri = (attachment) => {
    return allevaApi.Messages.getAttachmentPath(attachment.path);
  };

  $scope.refreshInbox = () => {

    $scope.fetchingEmails = true;
    $scope.emails.inbox   = {};

    $timeout(() => {
      $scope.getInbox()
      .then((emails) => {
        logService.console.info("Got Emails", emails);
        $scope.isLoading = false;

        $scope.emails.inbox = emails;

        $scope.filterEmails();

      })
      .catch((error) => {
        logService.console.error(error);
        $scope.isLoading      = false;
        $scope.fetchingEmails = false;

        if (typeof(error) === 'string' || error instanceof String) {
          new noty({
            text: error
          }).show();
        }
      });

    }, 200);

  };

  $scope.filterEmails = () => {
    var readEmails   = [];
    var unreadEmails = [];
    var sentEmails   = [];
    var attachments  = [];

    $scope.emails.inbox.forEach(email => {

      if(email.leadId != $scope.currentClient.leadId) { return; } // extra layer of protection

      email.message = email.message ? email.message : '';

      // Read Emails
      if(!email.isDraft && email.isRead && email.messageToRehabUserId === $scope.currentUser.rehabUserId) { // Read Emails
        if(!email.author){
          email.author = $scope.rehabDetails.rehabName;
        }
        if(!(email.createdDate instanceof moment)){
          email.createdDate = moment(email.createdDate);
        }
        readEmails.push(email);
      }
      // Unread Emails
      else if(!email.isDraft && !email.isRead && email.messageToRehabUserId === $scope.currentUser.rehabUserId){ // Unread Email
        email.author = $scope.rehabDetails.rehabName;
        if(!(email.createdDate instanceof moment)){
          email.createdDate = moment(email.createdDate);
        }
        unreadEmails.push(email);
      }
      // Sent Emails
      if(!email.isDraft && email.messageFromRehabUserId === $scope.currentUser.rehabUserId){ // Sent Email
        if(!email.author){
          email.author = $scope.currentUser.firstName + ' ' + $scope.currentUser.lastName;
        }
        if(!(email.createdDate instanceof moment)){
          email.createdDate = moment(email.createdDate);
        }
        sentEmails.push(email);
      }

      // Attachments
      if(!email.isDraft && email.hasAttachments &&
        ((email.messageToRehabUserId === $scope.currentUser.rehabUserId) || (email.messageFromRehabUserId === $scope.currentUser.rehabUserId))) {
          email.attachments.forEach(attachment => {
            if (attachment.fileName.match(/.(jpg|jpeg|png|gif)$/i)){
              attachment.isImage = true;
            }
          });
        attachments.push(email);
      }
    });

    // Sort emails by date
    const sortedReadEmails   = readEmails.sort((a, b) => b.createdDate - a.createdDate);
    const sortedUnreadEmails = unreadEmails.sort((a, b) => b.createdDate - a.createdDate);
    const sortedSentEmails   = sentEmails.sort((a, b) => b.createdDate - a.createdDate);
    const sortedAttachments  = attachments.sort((a, b) => b.createdDate - a.createdDate);

    $scope.emails.inbox.unreadEmails = sortedUnreadEmails;
    $scope.emails.inbox.readEmails   = sortedReadEmails;
    $scope.emails.inbox.sentEmails   = sortedSentEmails;
    $scope.emails.inbox.attachments  = sortedAttachments;

    $scope.currentClient.emails = $scope.emails;
    logService.console.info("Update Client: ", $scope.currentClient);

    $scope.fetchingEmails = false;
  };

  $scope.getEmailDate = (email) => {
    if(email.time) { return email.time; }

    var today = moment();
    var day   = moment(email.createdDate);

    if (today.startOf('day').isSame(day.startOf('day'))) {
      // They are on the same day
      email.time = moment(email.createdDate).format('LT');
      return email.time;
    } else {
      // They are not on the same day
      email.time = moment(email.createdDate).format('MMM DD');
      return email.time;
    }
  };

  $scope.convertToType = function(type) {
    switch (type) {
      case _FamilyFormTypeApplication:
        return "Application";
      case _FamilyFormTypeAcceptance:
        return "Acceptance";
      case _FamilyFormTypeDischarge:
        return "Discharge";
      case _FamilyFormTypeTreatmentPlan:
        return "Treatment Plan";
      case _FamilyFormTypeTreatmentPlanReview:
        return "Treatment Plan Review";
      case _ConsentFormType:
        return "Constent Form";
      case _AdmissionFormType:
        return "Admission Form";
      case _DischargeFormType:
        return "Discharge Form";
      case _BamrFormType:
        return "Bamr Form";
      case _RecTherapyFormType:
        return "Rec Therapy Form";
      case _CaseManagementFormType:
        return "Case Management Form";
      case _AssessmentFormType:
        return "Assessment Form";
      case _TreatmentPlanReviewFormType:
        return "Treatment Plan Review Form";
      default:
        return "";
    }
  };

  const refreshChecklist = () => {
    $scope.completedForms = [];
    $scope.incompleteForms = [];
    $scope.masterTpList = [];
    $scope.checklistLoaded = false;
    getParticipantChecklist({
                FacilityId : $scope.currentClient.facilityId,
                RehabUserId: $scope.currentUser.rehabUserId,
                LeadId: $scope.currentClient.leadId
            })
            .then(data => {
              data.formChecklist.forEach(form => {
                if(form.isCompleted){
                  $scope.completedForms.push(form);
                }
                else{
                  $scope.incompleteForms.push(form);
                }
              });
              data.tpChecklist.forEach(tp => {
                if(tp.isComplete){
                  tp.title = "Treatment Plan - " + $filter('utcToLocal')(tp.lastModified, null, 'M/DD/YYYY h:mm a');
                  tp.familyFormType = _FamilyFormTypeTreatmentPlan;
                  if($scope.currentUser.role == _FamilyMemberType){
                    if(tp.guardianSignature && tp.guardianSignature.data){
                      tp.completedDate = tp.guardianSignature.signatureDateTime;
                      $scope.completedForms.push(tp);
                    }
                    else{
                      $scope.incompleteForms.push(tp);
                    }
                  }
                  else{
                    if(tp.clientSignature && tp.clientSignature.data){
                      tp.completedDate = tp.clientSignature.signatureDateTime;
                      $scope.completedForms.push(tp);
                    }
                    else{
                      $scope.incompleteForms.push(tp);
                    }
                  }

                  $scope.masterTpList.push(tp);
                }
              });
              data.tprList.forEach(tpr => {
                tpr.clientSignature = tpr.txReviewDetails.clientSignature
                tpr.clientSignatureDate = tpr.txReviewDetails.clientSignatureDate
                tpr.guardianSignature = tpr.txReviewDetails.guardianSignature
                tpr.guardianSignatureDate = tpr.txReviewDetails.guardianSignatureDate
                tpr.title = "Treatment Plan Review - " + $filter('utcToLocal')(tpr.txReviewDetails.createdDated, null, 'M/DD/YYYY h:mm a');
                tpr.createdDate = tpr.txReviewDetails.createdDated
                tpr.familyFormType = _FamilyFormTypeTreatmentPlanReview;
                tpr.tpList = $scope.masterTpList;
                tpr.sectionList = [];
                JSON.parse(tpr.txReviewDetails.sortOrderSetting).forEach(section => {
                  if(section.IsActive){
                    tpr.sectionList.push(section);
                  }
                });
                if ($scope.currentUser.role == _FamilyMemberType && tpr.guardianSignature) {
                  tpr.completedDate = moment(tpr.guardianSignatureDate).format("M/DD/YYYY h:mm a");
                  $scope.completedForms.push(tpr);
                }
                else if(tpr.tpReviewOverview.clientSignatureStatus == "Completed"){
                  tpr.completedDate = moment(tpr.txReviewDetails.clientSignatureDate).format("M/DD/YYYY h:mm a");
                  $scope.completedForms.push(tpr);
                }
                else{
                  $scope.incompleteForms.push(tpr);
                }
              });
            })
            .catch(error => {
                logService.console.error("Error");
            })
            .finally(_ => {
              $scope.incompleteForms = $scope.incompleteForms.sort((a, b) =>  new Date(a.createdDate) - new Date(b.createdDate));
              $scope.completedForms = $scope.completedForms.sort((a, b) => new Date(b.completedDate) - new Date(a.completedDate));
              $scope.checklistLoaded = true;
              $scope.checklistBadgeCount = $scope.incompleteForms.length;
            });
  }

  $scope.trustAsHtml = function(string) {
    return $sce.trustAsHtml(string);
};

  function textEllipsis(str, maxLength, { side = "end", ellipsis = "..." } = {}) {
    if (str.length > maxLength) {
      switch (side) {
        case "start":
          return ellipsis + str.slice(-(maxLength - ellipsis.length));
        case "end"  :
             default:
          return str.slice(0, maxLength - ellipsis.length) + ellipsis;
      }
    }
    return str;
  }
}
