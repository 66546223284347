export default function CalculateFields() {
    var ctrl = this;
    ctrl.formComponents;
    ctrl.expression = null;
    ctrl.calculableFields = [];

    // Definitions
    const ExpressionType = { Field: 1, Operator: 2, Constant: 3, Misc: 4 };

    ctrl.$onInit = function () {
        this.advancedFormControl.registerEventHandler(ctrl.initialize);
    };

    ctrl.$postLink = async function () { }

    ctrl.initialize = () => {
        ctrl.formComponents = this.advancedFormControl.getFormComponents();
    };

    ctrl.calculate = () => {
        ctrl.InvalidCalc = false;
        ctrl.calculableFields = [];

        // TODO: Brittle -- find better way to get formId

        // TODO: why is ctrl.model FormController here?

       //const formId = ctrl.model ? ctrl.model.formId : ctrl.advancedFormControl.advancedFormId;
       const formId = ctrl.model && ctrl.model.formId ? ctrl.model.formId : ctrl.advancedFormControl.advancedFormId;

        // Step 1: We iterate through all form components subscribing to IsCalculable and get values.
        ctrl.formComponents.forEach(comp => {
            if (comp.originatingFormId == formId && comp.isCalculable) {
                var value = this.advancedFormControl.getComponentValueFromForm(comp.uuid)

                // Parse values: For checkboxes, values can be comma separated.
                let strArr = value.split(",");
                let sum = strArr.reduce(function (total, num) {
                    return parseFloat(total) + parseFloat(num);
                });

                comp.value = sum == '' ? 0 : sum;
                ctrl.calculableFields.push(comp)
            }

            // TODO: Very brittle. Probably want to use Datakind for this
            // Step 1.1: Grab the expression from the calculate button we pushed
            if (comp.originatingFormId == formId && comp.calculableExpression && comp.id == ctrl.facetId) {
                ctrl.expression = angular.fromJson(comp.calculableExpression);
            }

        });

        // Step 2: Build expression string
        var expressionString = "";

        ctrl.expression.forEach(expComp => {


            if (expComp.type == ExpressionType.Field) {
                const findComp = ctrl.calculableFields.find((comp) => comp.uuid == expComp.uuid);
                if (findComp) {
                    const compVal = findComp.value;
                    expComp = parseFloat(compVal);
                    expressionString += expComp;
                }
                else {
                    ctrl.InvalidCalc = true;
                }

            }
            else {
                expressionString += expComp.value;
            }


        });

        // Step 3: Evaluate the expression
        // TODO: Use safer way to do calculation
        if (!ctrl.InvalidCalc) {
            ctrl.result = eval(expressionString);
        }

    }

}




